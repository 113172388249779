import {services} from './services';

export const environment = {
  env: 'acc',
  production: true,
  endpoint: '/api',
  medicalContentEndpoint: 'https://medical-contents.acc.dearhealth.com',
  practitionerAuthTokenApi: 'https://r6qq8puisd.execute-api.us-east-1.amazonaws.com/acc/api/auth/token?providerId=',
  practitionerPatientAccessApi: 'https://4awix8sxsh.execute-api.us-east-1.amazonaws.com/acc/api/auth/patient/token/',
  region: 'us-east-1',
  launchDarklyId: '636a196bef3128120bd56610',
  cognitoConfig: {
    region: 'eu-west-2',
    userPoolId: 'eu-west-2_gzqE5zxbz',
    clientId: '4672khsvijjr2aeftlev3sk96h'
  },
  services
};
